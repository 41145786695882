
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import CategoryForm from './category-form.vue'
    import SortingModel from "@/store/entities/sortingmodel";

    class PageCategoryRequest extends PageRequest {
        status: boolean | null;
    }

    @Component({
        components: {CategoryForm}
    })
    export default class Category extends AbpBase {
        iIsVerifiedChange(val: string) {
            if (val === 'Yes') {
                this.pagerequest.status = true;
            } else if (val === 'No') {
                this.pagerequest.status = false;
            } else {
                this.pagerequest.status = null;
            }
        }

        edit() {
            this.editModalShow = true;
        }

        async search() {
            await this.pageChange(1);
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        //filters
        pagerequest: PageCategoryRequest = new PageCategoryRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        get list() {
            return this.$store.state.category.list;
        };

        get loading() {
            return this.$store.state.category.loading;
        }

        create() {
            this.createModalShow = true;
        }

        async pageChange(page: number) {
            this.$store.commit('category/setCurrentPage', page);
            await this.getpage();
        }

        async pagesizeChange(pagesize: number) {
            this.$store.commit('category/setPageSize', pagesize);
            await this.getpage();
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'category/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.category.pageSize;
        }

        get totalCount() {
            return this.$store.state.category.totalCount;
        }

        get currentPage() {
            return this.$store.state.category.currentPage;
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('Code'),
            key: 'code',
            sortable: 'custom'
        }, {
            title: this.L('Status'),
            key: 'status',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('category/edit', await this.$store.dispatch({
                                    type: 'category/get',
                                    data: params.row.id
                                }));
                                this.edit();
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Category Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'category/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            await this.getpage();
        }
    }
